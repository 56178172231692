import React, { useState } from "react"
import styled from "styled-components"
import { Navbar, Nav } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.css"
import jQuery from "jquery"
import Modal from "react-bootstrap/Modal"
import Popup_ans from "../../pages/popup-answer"
import "../../style/modal.css"
import Logo from "../../static/logo.png"
import { Link } from "gatsby"
const Container = styled.div`
  .logo {
    width: 14vw;
    margin-left: 0.1vw;
  }
  .bg-light {
    background-color: #fff !important;
  }

  .navbar-nav {
    align-items: center;
  }
  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-brand {
    padding-bottom: 0;
    padding-top: 0;
  }

  .nav-item {
    white-space: nowrap;
    a{
    color: #000000 !important;
    font-size: 0.9vw;
    font-weight: 500;
    padding-right: 1vw !important;
    padding-left: 0.4vw !important;
    width: 9vw;
    text-align: center;
    }
  }

  .nav-item:hover {
    a {
    color: #black !important;
    text-decoration: none;
    }
  }

  .question-btn {
    border: none;
    background-color: #c1141e;
    color: white;
    padding: 0.7vw 2vw 0.6vw;
    font-weight: bold;
    border-radius: 5000px;
  }

  .header-top {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-light .navbar-toggler {
    border: none;
  }
  .navbar {
    padding: 1vw 2vw;
    width: 100vw;
  }

  .nav {
    z-index: 999;
    position: fixed;
    left: 50%;
    width: 100%;
    padding: 1.4vw 2vw;
    text-align: center;
    transform: translate(-50%);
    transition: all 0.3s;
  }

  .show-480 {
    display: none;
  }

  .hide-480 {
    display: block;
  }
  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 480px) {
    .show-480 {
      display: block;
    }

    .hide-480 {
      display: none;
    }
    .logo {
      width: 50vw;
      margin-left: 1vw;
    }

    .nav-item {
      padding: 3vw 0;
      a {
      font-size: 4.5vw;
      
      // width: fit-content;
      }
    }

    .navbar-nav {
      align-items: flex-start;
      padding: 0 6.5vw;
      box-shadow: 0px 0px 6px 0 rgb(0 0 0 / 16%);
    }

    .header-top {
      display: flex;
      width: 100vw;
      justify-content: space-between;
      align-items: center;
      padding: 4vw 4vw 2vw;
    }

    .question-btn {
      background-color: transparent;
      color: #000000;
      font-size: 4.5vw;
      padding: 0;
      font-weight: 500;
    }

    .navbar {
      padding: 0;
    }

    .navbar-toggler-icon {
      margin-top: -1.5vw;
    }
  }
`

const Header = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  var lastScrollTop = 0
  var delta = 15
  if (typeof window !== "undefined") {
    window.$ = window.jQuery = jQuery

    $(window).scroll(function () {
      var scrollTop = $(this).scrollTop()
      if (Math.abs(lastScrollTop - scrollTop) <= delta)
        if (scrollTop > lastScrollTop && lastScrollTop > 0) {
          /* 화면에 나오지 않을 때, top값은 요소가 보이지 않을 정도로 사용해야함 */
          $(".nav").css("top", "-100px")
          $(".nav").css("box-shadow", "none")
        } else {
          $(".nav").css("top", "0px")
          $(".nav").css("box-shadow", "0px 0px 6px 0 rgb(0 0 0 / 16%)")
        }
      lastScrollTop = scrollTop
    })
  }
  return (
    <Container>
      <Navbar className="nav" bg="light" expand="lg">
        <div className="header-top">
          <Navbar.Brand className="logo-image" href="/">
            <img className="logo" src={Logo}></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
           <Nav.Item>
             <Link to="/aboutUs"> ABOUT US </Link>
             </Nav.Item>
           <Nav.Item >
             <Link to="/space"> 공간소개 </Link>
             </Nav.Item>
            <Nav.Item >
              <Link to="/branch"> 지점현황 </Link>
              </Nav.Item>
            <Nav.Item>
              <Link to="/news"> 언론 속 공간샘 </Link>
              </Nav.Item>
            <Nav.Item>
              <Link to="/contents"> 공간샘 콘텐츠 </Link>
              </Nav.Item>
            <Nav.Item>
              <Link to="/franchise">
              <button className="question-btn">가맹문의</button>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal className="answer-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Popup_ans />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Header
