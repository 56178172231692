import React from "react"
import styled from "styled-components"

import SamlabLogo from "../../static/samlab-logo.svg"
const Container = styled.div`
  clear: both;

  footer {
    padding: 2vw 0;
  }
  .footer-part {
    display: flex;
    font-size: 1vw;
    justify-content: center;
  }

  .footer-part img {
    width: 13vw;
    margin-top: -3.5vw;
  }

  .part1,
  .part2 {
    display: flex;
    flex-direction: column;
    padding: 0.7rem 2vw;
    line-height: 2;
    justify-content: space-between;
    font-size: 1vw;
  }

  .customer-service-number {
    font-size: 1.4vw;
  }

  .part1 {
    border-right: 1px solid #707070;
  }

  .samlab-instar {
    margin-left: 1vw;
  }

  .customer-service-number a {
    color: #363636;
    font-weight: bold;
  }

  .customer-service-number a:hover {
    text-decoration: none;
  }


  .footer-small-line {
    margin: 0 0.5rem;
    border-left: 1px solid #707070;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    background-color: #fafafa;
    margin-bottom: 15vw;
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: flex;
    }

    .footer-part-480 {
      flex-direction: column;
      padding: 13vw 10vw 5vw;
    }

    .footer-part-480 img {
      width: 40vw;
      margin-bottom: 5vw;
    }

    .part1 {
      border-right: none;
      padding: 0;
      font-size: 3.5vw;
    }

    .part2 {
      border-left: solid 0.5px #707070;
      padding: 0px 0 0 0.5vw;
      font-size: 3.5vw;
    }

    .text-part1 {
      display: flex;
      font-size: 3.5vw;
      justify-content: space-between;
      margin-bottom: 5vw;
    }

    .customer-service-number {
      font-size: 5vw;
      color: #363636;
      font-weight: bold;
      line-height: 2.5;
    }

   
    .samlab-instar span {
      font-weight: 500;
    }

    .text-part2 span {
      font-size: 3.5vw;
      font-weight: 500;
      line-height: 1.92;
      color: #4a4a4a;
    }

    span.footer-title {
      font-weight: bold;
      color: #000000;
    }

    span.footer-address {
      font-size: 3.2vw;
    }

    .footer-add-copy {
      font-size: 3.5vw;
      font-weight: 500;
      margin-top: 4vw;
      line-height: 1.82;
    }

    .samlab-instar {
      margin-left: 0;
    }

}
`

const Footer = () => {
  return (
    <Container>
      <footer>
        <div className="footer-part hide-480">
          <img src={SamlabLogo} />
          <div className="part1">
            <span className="customer-service-number hide-480">가맹상담 1688-4264</span>
            <span>월-금요일: 9:00 - 18:00</span>
            <span>점심시간: 12:00 - 13:00</span>
          </div>
          <div className="part2">
            <span>
              www.samlab.co.kr
              <span className="samlab-instar">instagram:samlab_official</span>
            </span>
            <span>
              샘랩주식회사<span className="footer-small-line"></span>대표 :
              심세용<span className="footer-small-line"></span>사업자 등록번호 :
              531-87-00830<span className="footer-small-line"></span>
              개인정보취급방침
            </span>
            <span>
              Copyright<span className="ml-1 text-center">&copy;</span>
              2019 샘랩 Corporation. 서울특별시 금천구 가산동 371-106 번지 가산w센터 603호 
            </span>
          </div>
        </div>
        <div className="footer-part-480 show-480">
          <img src={SamlabLogo} />
          <span className="customer-service-number show-480"><a hide-480 href='tel:1688-4264'>가맹상담 1688-4264</a></span>
          <div className="text-part1">
            <div className="part1">
              <span>월-금요일: 9:00 - 18:00</span>
              <span>점심시간: 12:00 - 13:00</span>
            </div>
            <div className="part2">
              <span>
                www.samlab.co.kr
                <br />
                <span className="samlab-instar">
                인스타그램:<span>samlab_official</span>
                </span>
              </span>
            </div>
          </div>
          <div className="text-part2">
            <span>
              <span className="footer-title">샘랩주식회사</span>
              <br />
              대표 : 심세용
              <br />
              사업자 등록번호 : 531-87-00830
              <br />
              개인정보취급방침
            </span>
          </div>
          <span className="footer-add-copy">
            <span className="footer-address">
            서울특별시 금천구 가산동 371-106 번지 가산w센터 603호 
            </span>
            <br />
            Copyright<span className="ml-1 text-center">&copy;</span>
            2019 샘랩 Corporation.
          </span>
        </div>
      </footer>
    </Container>
  )
}

export default Footer
