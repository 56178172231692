import React from "react"
import { Helmet } from "react-helmet"
import image from "../static/titlelogo.png"

const SEO = () => {
  return (
    <Helmet
      htmlAttributes={{ lang: "ko" }}
      title={`공간샘 스터디 카페`}
      // description={`AI학습 멘토가 있는 스터디카페 공간샘, 이제는 관리형이다!
      // 공간스터디카페 창업 공간샘이 잘합니다. `}
    >
      <meta name="title" content="공간샘 스터디 카페" />
      <meta name="image" content={image} />
      <meta
        name="description"
        content="AI학습 멘토가 있는 스터디카페 공간샘, 이제는 관리형이다! 
       공간스터디카페 창업 공간샘이 잘합니다."
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content="https://www.saemcafe.com" />
      <meta
        name="facebook-domain-verification"
        content="6gvfuixz4ora87gg9y55a8o3sppul9"
      />
      <link
        href="//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link rel=" shortcut icon" href={image} />
      <link rel="icon" href={image} />
    </Helmet>
  )
}

export default SEO
