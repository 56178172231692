import React, { useState } from "react"
import styled from "styled-components"
import Header from "./Header"
import Footer from "./Footer"
import Main from "./Main"
import GlobalStyle from "../../style/GlobalStyle"
import Modal from "react-bootstrap/Modal"
import Popup_ans from "../../pages/popup-answer"
import "../../style/modal.css"
import SEO from "../SEO"

const Container = styled.div`
  .floating_btn {
    background-color: #d96e62;
    width: 10vw;
    height: 10vw;
    padding: 3.2vw 0;
    font-size: 1.6vw;
    font-weight: bold;
    color: #fff;
    border-radius: 100px;
    text-align: center;
    position: fixed;
    z-index: 80;
    line-height: 1.25;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.44);
    bottom: 0;
    right: 0;
    margin-right: 2.5vw;
    margin-bottom: 2.5vw;
    cursor: pointer;
  }

  Main {
    padding-top: 4vw;
  }

  .floating_btn_480 {
    display: none;
  }
  @media screen and (max-width: 480px) {
    .floating_btn {
      display: none;
    }

    Main {
      padding-top: 12vw;
    }

    .floating_btn_480 {
      display: flex;
      position: fixed;
      width: 100vw;
      bottom: 0;
      right: 0;
      align-items: center;
      background-color: #d96e62;
      z-index: 999;
    }

    .floating_btn_480 span {
      font-size: 6vw;
      color: #fff;
      width: 50vw;
      text-align: center;
      padding: 5vw 0 4vw;
      font-weight: bold;
      display: block;
    }

    .left-item {
      border-left: 2px solid #fff;
    }
  }
  @media screen and (max-width: 375px) {
    Main {
      padding-top: 15vw;
    }
  }
`
const Layout = ({ children }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Container>
      <SEO />
      <GlobalStyle />
      <div className="floating_btn_480">
        <span onClick={handleShow}>가맹문의</span>
        <a
          href="tel:1688-4264"
          style={{ textDecoration: "none" }}
          className="intro-r480"
        >
          <span className="left-item">1688-4264</span>
        </a>
      </div>

      <div className="floating_btn" onClick={handleShow}>
        가맹문의
        <br />
        바로가기
      </div>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <Modal className="answer-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Popup_ans />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default Layout
